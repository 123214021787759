import Chart from "react-apexcharts";
import { Box, Theme, Typography } from "@mui/material";
import { CircleOutlined } from "@mui/icons-material";
import { useForm, useWatch } from "react-hook-form";
import { useCompanyContext } from "contexts/CompanyContext";
import { dark, mid, middark } from "pages/variables";
import { colors } from "pages/chartColors";
import { formatNumber } from "utils/number";
import useMediaQuery from "@mui/material/useMediaQuery";
import SortSelect from "components/SortSelect";
import useShareholdersSort from "./useShareholdersSort";
import Banner from "./Banner";
import { Dispatch, SetStateAction } from "react";

type FormInputs = {
  sortBy: { label: string; data: string };
  isDescending: boolean;
};

type Props = {
  userHasUboVerifyEnabled: boolean;
  setCurrentTabValue: Dispatch<SetStateAction<string>>;
};

const Shareholders = ({
  userHasUboVerifyEnabled,
  setCurrentTabValue,
}: Props): JSX.Element => {
  const { companyProfile } = useCompanyContext();

  const sortOptions = [
    { label: "Percentage", data: "percentage" },
    { label: "Name", data: "name" },
    { label: "Shareholder type", data: "shareholder-type" },
    { label: "Share type", data: "share-type" },
  ];

  const { control, setValue } = useForm<FormInputs>({
    defaultValues: {
      sortBy: sortOptions[0],
      isDescending: true,
    },
  });

  const sorting = useWatch({ control, name: "sortBy" });
  const isDescending = useWatch({ control, name: "isDescending" });
  const sortedShareholders = useShareholdersSort(
    companyProfile?.directorAndShareDetails?.shareHolders || [],
    sorting.data,
    isDescending,
  );

  const chartSeries = sortedShareholders.map((shareholder) =>
    Number(shareholder.percentage),
  );

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );

  const wordWrap = (label: string) => {
    const maxLength = 45;
    const lines = [];

    for (const word of label.split(" ")) {
      if (lines.length == 0) {
        lines.push(word);
      } else {
        const i: number = lines.length - 1;
        const line: string = lines[i];

        if (line.length + 1 + word.length <= maxLength) {
          lines[i] = `${line} ${word}`;
        } else {
          lines.push(word);
        }
      }
    }

    return lines.join("</br>");
  };

  const chartOptions = {
    chart: {
      id: "shareholder-chart",
    },
    labels: sortedShareholders.map(
      ({ name, shareholderType, shareCount, shareType }) =>
        `${wordWrap(name)} <span style="color: ${middark}">${
          shareholderType || ""
        } ${formatNumber(shareCount)} ${shareType || ""}</span>`,
    ),
    dataLabels: {
      style: {
        fontSize: "12px",
        fontFamily: "Anthro",
      },
      dropShadow: {
        enabled: false,
      },
    },
    fill: {
      pattern: {
        strokeWidth: 0,
      },
    },
    legend: {
      position: isMobile ? ("top" as const) : ("right" as const),
      markers: {
        width: 10,
        height: 10,
        radius: 10,
      },
    },
    colors,
  };

  const onAscDescHandler = () => {
    setValue("isDescending", !isDescending);
  };

  const shareCapital =
    companyProfile?.directorAndShareDetails?.shareHolderSummary?.shareCapital;

  return (
    <Box>
      {userHasUboVerifyEnabled && (
        <Banner setCurrentTabValue={setCurrentTabValue} />
      )}
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}
      >
        <SortSelect
          control={control}
          options={sortOptions}
          onAscDescHandler={onAscDescHandler}
          isDescending={isDescending}
          inputWidth="9rem"
        />
        <Typography fontSize="1.125rem" fontFamily="Sohne">
          {companyProfile.name}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <CircleOutlined
            sx={{ width: "0.5em", height: "0.5em", color: mid }}
          />
          <Typography fontSize="0.85rem" color={dark}>
            The total amount of shares is{" "}
            {formatNumber(
              companyProfile?.directorAndShareDetails?.shareHolders?.[0]
                ?.totalShareCount || 0,
            )}
          </Typography>
        </Box>
        {shareCapital && (
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <CircleOutlined
              sx={{ width: "0.5em", height: "0.5em", color: mid }}
            />
            <Typography fontSize="0.85rem" color={dark}>
              The total value of shares is {formatNumber(shareCapital)}
            </Typography>
          </Box>
        )}
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="donut"
          height="300px"
        />
      </Box>
    </Box>
  );
};

export default Shareholders;
