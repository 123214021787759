import {
  Alert,
  Box,
  CircularProgress,
  Drawer,
  Portal,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  SelectChangeEvent,
  MenuItem,
  Select,
} from "@mui/material";
import { Add, Download } from "@mui/icons-material";
import { toDisplayDate } from "utils/dates";
import { useApi } from "hooks/useApi";
import { useRef, useEffect, useState } from "react";
import { midlight, secondary, secondary_mid } from "../variables";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import AddUser from "./AddUser";
import EditUser from "./Actions/EditUser";
import Action from "./Actions/Action";
import ActionButton from "components/Button";
import useExportAccounts from "../../hooks/useExportAccounts";
import useRemoveUser from "../../hooks/useRemoveUser";
import useResetPassword from "../../hooks/useResetPassword";
import { PaginationResult, User } from "../../types";
import TablePagination from "@mui/material/TablePagination";
import Search from "components/Search";
import ErrorMessage from "components/ErrorMessage";
import React from "react";
import Popup from "./Actions/Popup";
import useActivateUser from "../../hooks/useActivateUser";
import { Userpilot } from "userpilot";

const centeredStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function Admin(): JSX.Element {
  const [editUserShown, toggleEditUserShown] = useState<boolean>(false);
  const [showRemoveUser, setShowRemoveUser] = useState<boolean>(false);
  const [showActivateUser, setShowActivateUser] = useState<boolean>(false);
  const [showResetUserPassword, setShowResetUserPassword] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isRemovingUser, setIsRemovingUser] = useState<boolean>(false);
  const [isActivatingUser, setIsActivatingUser] = useState<boolean>(false);
  const [isResettingUserPassword, setIsResettingUserPassword] =
    useState<boolean>(false);
  const exportAccountsTrigger = useExportAccounts();
  const removeUserTrigger = useRemoveUser(selectedUser?.id ?? "");
  const activateUserTrigger = useActivateUser(selectedUser?.id ?? "");
  const resetPasswordTrigger = useResetPassword(selectedUser?.id ?? "");
  const navigate = useNavigate();
  const { isAdmin, user } = useUserContext();
  const [rowsPerPage, setRowsPerPage] = useState(10); //default starting page size
  const [page, setPage] = useState(0);
  const [filterUsers, setFilterUsers] = useState("");
  const [showActiveArchivedUsers, setStateShowActiveArchivedUsers] =
    useState("1");

  useEffect(() => {
    if (isAdmin !== undefined) {
      if (!isAdmin) {
        navigate("/forbidden", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdmin]);

  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentTop, setContentTop] = useState<number>(0);
  const { data, isLoading } = useApi<PaginationResult<User>>(
    `/accounts/organization/members?pageNumber=${
      page + 1 //page is expected to start from 1 unlike the table that wants 0
    }&pageSize=${rowsPerPage}&filter=${filterUsers}&active=${showActiveArchivedUsers}`,
  );
  const [users, setUsers] = useState<User[] | undefined>(data?.results);
  const [totalCount, setTotalCount] = useState<number | undefined>(
    data?.totalCount,
  );

  useEffect(() => {
    if (!isLoading && data) {
      setUsers(data.results);
      setTotalCount(data.totalCount);
    }
  }, [isLoading, data]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [actionMessage, setActionMessage] = useState<string>("");
  const [isSnackbarError, setSnackbarError] = useState<boolean>(false);

  const onEditUserList = (
    str: string,
    isError: boolean,
    isEditAction: boolean,
  ) => {
    triggerSnackBar(str, isError);

    if (!isError) {
      if (isEditAction) {
        // MODIFY USER
        const newUsers = users?.map((user: User, index: number) => {
          if (index === selectedIndex) {
            return {
              ...user,
              firstName: selectedUser?.firstName,
              lastName: selectedUser?.lastName,
              role: selectedUser?.role,
            };
          }

          return user;
        }) as User[];
        setUsers(newUsers);
      } else {
        // CREATE USER
        refreshPage();
      }
    }
  };

  const refreshPage = () => {
    const currentPage = page;
    setPage(1); // cheap mechanism to refresh the list and keep the current page
    setPage(0); // the failing due to missing page gives 'just' console spam
    setPage(currentPage);
  };

  const exportAccounts = async () => {
    setIsExporting(true);
    try {
      await exportAccountsTrigger();
    } catch (error: unknown) {
      await handleError(error);
    }
    setIsExporting(false);
  };

  const removeUser = async () => {
    setIsRemovingUser(true);
    try {
      if (!selectedUser?.id) {
        triggerSnackBar("Missing user id.", true);
        return;
      }

      await removeUserTrigger();
      refreshPage();
      triggerSnackBar("User deleted.", false);
      setShowRemoveUser(false);
    } catch (error: unknown) {
      await handleError(error);
    }
    setIsRemovingUser(false);
  };

  const activateUser = async () => {
    setIsActivatingUser(true);
    try {
      if (!selectedUser?.id) {
        triggerSnackBar("Missing user id.", true);
        return;
      }

      await activateUserTrigger();
      refreshPage();
      triggerSnackBar("User activated.", false);
      setShowActivateUser(false);
    } catch (error: unknown) {
      await handleError(error);
    }
    setIsActivatingUser(false);
  };

  const resetUserPassword = async () => {
    setIsResettingUserPassword(true);
    try {
      if (!selectedUser?.id) {
        triggerSnackBar("Missing user id.", true);
        return;
      }

      await resetPasswordTrigger();
      triggerSnackBar(
        "We've just sent an email to reset the user's password.",
        false,
      );
      setShowResetUserPassword(false);
    } catch (error: unknown) {
      await handleError(error);
    }
    setIsResettingUserPassword(false);
  };

  // TODO:: Needs a type for Error, handles too many at once
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = async (error: any) => {
    let msg = typeof error === "object" ? error?.message : (error as string);

    if (error?.response?.json) {
      try {
        const errorData = await error.response.json();
        msg = errorData.message;
      } catch {
        console.error("Errored displaying error message.");
      }
    }

    triggerSnackBar(msg, true);
    console.error(error);
  };

  const onEditUserActionClick = (user: User, index: number) => {
    toggleEditUserShown(true);
    setSelectedUser(user);
    setSelectedIndex(index);
  };

  const onRemoveUserActionClick = async (user: User, index: number) => {
    setShowRemoveUser(true);
    setSelectedUser(user);
    setSelectedIndex(index);
  };

  const onPasswordResetActionClick = async (user: User) => {
    setShowResetUserPassword(true);
    setSelectedUser(user);
  };

  const onActivateUserActionClick = async (user: User, index: number) => {
    setShowActivateUser(true);
    setSelectedUser(user);
    setSelectedIndex(index);
  };

  const triggerSnackBar = (msg: string, isError: boolean) => {
    if (msg && msg.charAt(msg.length - 1) != ".") {
      msg = msg + "."; // ensure message ends with a dot
    }

    setActionMessage(msg);
    setSnackbarError(isError);
    setSnackbarOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // on change of value (by typing) of the "Search user's name or email" text
  const onSearchInput = (input: string) => {
    Userpilot.track("Searched User List");
    setPage(0); // reset to page 0 on filter change
    setFilterUsers(input);
  };

  // on change of the select show Active/Archived users
  const setShowActiveArchivedUsers = (event: SelectChangeEvent<string>) => {
    Userpilot.track("User Active Filter Changed");
    setPage(0); // reset to page 0 on changing active/inactive visualization
    setStateShowActiveArchivedUsers(event.target.value);
  };

  useEffect(() => {
    const { current: content } = contentRef;

    if (content) {
      setContentTop(content.getBoundingClientRect().top);
    }
  }, [contentRef]);

  useEffect(() => {
    Userpilot.track("Entered admin page");
  }, []);

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        padding: "50px 70px",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          padding: "40px 25px",
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <Box>
          <Typography
            variant="h4"
            fontSize="2.375rem"
            fontFamily="Sohne"
            color="primary.dark"
            mb={1}
          >
            User Management
          </Typography>
        </Box>

        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginRight: "10px",
              height: "40px",
            }}
          >
            <Search
              textInputProps={{
                placeholder: "Search user's name or email",
              }}
              onSearchInput={onSearchInput}
              hasClearInputButton={true}
            />
            <Select
              labelId="show-active-lbl"
              id="show-active"
              required
              onChange={setShowActiveArchivedUsers}
              value={showActiveArchivedUsers}
              sx={{
                width: 107,
                fontSize: "0.8rem",
                borderRadius: 2,
                padding: 0.5,
                mr: 2,
              }}
            >
              <MenuItem value={"1"}>Active</MenuItem>
              <MenuItem value={"0"}>Inactive</MenuItem>
            </Select>
            <ActionButton
              size="small"
              onClick={() => {
                Userpilot.track("Downloaded user csv Button Clicked");
                exportAccounts();
              }}
              sx={{ maxHeight: 40, paddingX: 1.5 }}
            >
              {isExporting && (
                <Box
                  sx={{
                    height: "24px",
                    marginRight: "10px",
                  }}
                >
                  <CircularProgress color="info" size="24px" />
                </Box>
              )}
              {!isExporting && <Download />}
              &nbsp;Download Users
            </ActionButton>
          </Box>
          <ActionButton
            size="small"
            onClick={() => {
              Userpilot.track("Add User Button Clicked");
              setOpenModal(!openModal);
            }}
            sx={{
              maxHeight: 40,
              paddingX: 3,
              backgroundColor: secondary_mid,
              color: "#223243",
              "&:hover": {
                backgroundColor: secondary,
              },
            }}
          >
            <Add />
            Add User
          </ActionButton>
        </Box>
        <Drawer
          anchor="right"
          open={openModal}
          onClose={() => setOpenModal(false)}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "primary.light",
            },
          }}
        >
          <AddUser
            onClose={() => setOpenModal(false)}
            toastHandler={onEditUserList}
          />
        </Drawer>
      </Box>

      {isLoading && (
        <Box
          ref={contentRef}
          sx={{
            ...centeredStyle,
            height: `calc(100vh - ${contentTop}px)`,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <CircularProgress color="success" size="2rem" />
            <Typography variant="caption" color="grey.500" fontWeight={500}>
              Loading...
            </Typography>
          </Box>
        </Box>
      )}
      {!isLoading && (!users || users.length === 0) && (
        <Box
          sx={{
            ...centeredStyle,
            height: `calc(100vh - ${contentTop}px)`,
          }}
        >
          <ErrorMessage message="No users found" />
        </Box>
      )}
      {!isLoading && users && (
        <Box>
          <TableContainer>
            <Table
              sx={{
                gridRowGap: "10px",
                marginTop: "20px",
                padding: "12px 20px",
              }}
              size="small"
            >
              <TableHead>
                <TableRow
                  sx={{
                    borderBottom: `1px solid ${midlight}`,
                  }}
                >
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography color="primary.dark">Name</Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography color="primary.dark">Surname</Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography color="primary.dark">Email</Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography color="primary.dark">Last login</Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography color="primary.dark">Created</Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography color="primary.dark">Role</Typography>
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }}>
                    <Typography color="primary.dark">Actions</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map(
                  (
                    {
                      id: accountId,
                      firstName,
                      lastName,
                      email,
                      lastLoginDate,
                      createdDate,
                      role,
                    },
                    index,
                  ) => (
                    <TableRow
                      key={accountId}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                        gridTemplateColumns: "20% 20% 15% 15% 15% 10%",
                        padding: "12px 20px",
                        background: index % 2 ? "" : "rgb(244, 245, 245)",
                      }}
                    >
                      <TableCell
                        style={{ borderBottom: "none" }}
                        component="th"
                        scope="row"
                      >
                        <Typography variant="body2" color="primary.dark">
                          {firstName}
                        </Typography>
                      </TableCell>
                      <TableCell
                        style={{ borderBottom: "none" }}
                        component="th"
                        scope="row"
                      >
                        <Typography variant="body2" color="primary.dark">
                          {lastName}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        <Typography variant="body2" color="primary.dark">
                          {email}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        <Typography variant="body2" color="primary.dark">
                          {lastLoginDate
                            ? toDisplayDate(new Date(lastLoginDate))
                            : "Never"}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        <Typography variant="body2" color="primary.dark">
                          {toDisplayDate(new Date(createdDate))}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        <Typography variant="body2" color="primary.dark">
                          {role == "Standard" ? "USER" : "ADMIN"}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        <Action
                          isActiveUserMenu={showActiveArchivedUsers != "0"}
                          onEditClick={() => {
                            Userpilot.track("Edit User Button Clicked");
                            onEditUserActionClick(users[index], index);
                          }}
                          onRemoveClick={() => {
                            Userpilot.track("Delete User Button Clicked");
                            onRemoveUserActionClick(users[index], index);
                          }}
                          onPasswordResetClick={() => {
                            Userpilot.track(
                              "Reset Users Password Button Clicked",
                            );
                            onPasswordResetActionClick(users[index]);
                          }}
                          onActivateClick={() => {
                            Userpilot.track("Activate User Button Clicked");
                            onActivateUserActionClick(users[index], index);
                          }}
                          hideDeleteButton={users[index].email == user?.email}
                        />
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={totalCount ?? -1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={function defaultLabelDisplayedRows({
              from,
              to,
            }) {
              return `Showing ${from}–${to} of ${
                totalCount !== -1 ? `${totalCount} users` : `more than ${to}`
              }`;
            }}
            labelRowsPerPage="Users per page:"
            nextIconButtonProps={{ color: "success" }}
          />
        </Box>
      )}
      <Drawer
        anchor="right"
        open={editUserShown}
        onClose={() => toggleEditUserShown(false)}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "primary.light",
          },
        }}
      >
        <EditUser
          onClose={() => toggleEditUserShown(false)}
          userDetails={selectedUser}
          setUserDetails={setSelectedUser}
          toastHandler={onEditUserList}
        />
      </Drawer>
      <Popup
        setShowPopup={() => setShowRemoveUser(true)}
        actionCancel={() => setShowRemoveUser(false)}
        actionConfirm={async () => removeUser()}
        showPopup={showRemoveUser}
        isLoading={isRemovingUser}
        titleText={"Delete User"}
        bodyText={
          "Are you sure you want to delete " +
          selectedUser?.firstName +
          " " +
          selectedUser?.lastName +
          "?"
        }
        iconName={"PersonOff"}
        actionConfirmTitle={"Delete"}
      />
      <Popup
        setShowPopup={() => setShowActivateUser(true)}
        actionCancel={() => setShowActivateUser(false)}
        actionConfirm={async () => activateUser()}
        showPopup={showActivateUser}
        isLoading={isActivatingUser}
        titleText={"Activate User"}
        bodyText={
          "Are you sure you want to activate " +
          selectedUser?.firstName +
          " " +
          selectedUser?.lastName +
          "?"
        }
        iconName={"ManageAccounts"}
        actionConfirmTitle={"Activate"}
      />
      <Popup
        setShowPopup={() => setShowResetUserPassword(true)}
        actionCancel={() => setShowResetUserPassword(false)}
        actionConfirm={async () => resetUserPassword()}
        showPopup={showResetUserPassword}
        isLoading={isResettingUserPassword}
        titleText={"Reset Password"}
        bodyText={
          "Are you sure you want to send " +
          selectedUser?.firstName +
          " " +
          selectedUser?.lastName +
          " a password reminder email?"
        }
        iconName={"LockReset"}
        actionConfirmTitle={"Send"}
      />
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          sx={{ marginRight: "100px" }}
        >
          <Alert
            onClose={handleClose}
            severity={isSnackbarError ? "error" : "success"}
            sx={{ width: "100%" }}
          >
            {actionMessage}
          </Alert>
        </Snackbar>
      </Portal>
    </Box>
  );
}
