import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { useAddUser, Inputs } from "hooks/useAddUser";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TextInput } from "components/Form";
import { Toast } from "components/Toast/Toast";
import AddUserButton from "components/Button";
import { lightest } from "../variables";
import RadioButtonGroupInput from "components/Form/RadioButtonGroupInput";
import ErrorLabel from "components/ErrorMessage/ErrorLabel";

type Props = {
  onClose: () => void;
  toastHandler: (v: string, f: boolean, isEditAction: boolean) => void;
};

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email().required("Email is required"),
    role: yup.string().required("Role is required"),
    notifyByEmail: yup.boolean().required("Notify by Email is required"),
  })
  .required();

const AddUser: React.FC<Props> = ({ onClose, toastHandler }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const { mutateAsync: addUser, isLoading } = useAddUser(toastHandler);
  const [openToast, setOpenToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [role, setRole] = useState<string>("Standard");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [enableSequenceAdd, setSequenceAdd] = React.useState<boolean>(false);

  useEffect(() => {
    setValue("role", role);
    setValue("firstName", firstName);
    setValue("lastName", lastName);
    setValue("email", email);
    // TODO bad use of useEffect, https://react-hook-form.com/get-started#IntegratingControlledInputs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, firstName, lastName, email]);

  type EventHandler = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

  const setFirstNameHandler = (event: EventHandler) => {
    setFirstName(event.target.value);
    setValue("firstName", firstName);
  };

  const setLastNameHandler = (event: EventHandler) => {
    setLastName(event.target.value);
    setValue("lastName", lastName);
  };

  const setEmailHandler = (event: EventHandler) => {
    setEmail(event.target.value);
    setValue("email", email);
    setErrorMessage("");
  };
  const onSubmit = async (data: Inputs) => {
    const user = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      credits: data.credits,
      role: data.role,
      notifyByEmail: data.notifyByEmail,
    };

    try {
      setOpenToast;
      await addUser(user);
      if (!enableSequenceAdd) {
        onClose();
      } else {
        setFirstName("");
        setLastName("");
        setEmail("");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error?.response?.body) {
        try {
          const errorData = await error.response.json();
          setErrorMessage(errorData.message);
          return;
        } catch {
          console.error("Errored displaying error message.");
        }
      }
      setErrorMessage("Generic error occurred.");
    }
  };

  const onError = () => {
    console.error(errors);
  };

  const handleChangeSequenceAdd = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSequenceAdd(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <Box p="40px 20px 0px 32px" width="25vw">
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            fontSize="1.125rem"
            fontFamily="Sohne"
            color="primary.dark"
          >
            New User
          </Typography>
          <IconButton
            aria-label="close-settings"
            sx={{ alignSelf: "center" }}
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          >
            <Clear sx={{ color: "primary.dark" }} fontSize="small" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            marginTop: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <TextInput<Inputs>
              name="firstName"
              label="First name"
              value={firstName}
              onChange={(e) => setFirstNameHandler(e)}
              sx={{
                label: {
                  paddingLeft: "10px",
                  color: "rgb(2, 42, 55)",
                  zIndex: 1,
                },
                input: {
                  paddingLeft: "10px",
                  border: "1px solid rgb(211, 217, 219)",
                  borderRadius: "8px",
                  backgroundColor: lightest,
                },
              }}
              control={control}
            />
            <TextInput<Inputs>
              name="lastName"
              label="Last name"
              value={lastName}
              onChange={(e) => setLastNameHandler(e)}
              sx={{
                label: {
                  paddingLeft: "10px",
                  color: "rgb(2, 42, 55)",
                  zIndex: 1,
                },
                input: {
                  paddingLeft: "10px",
                  border: "1px solid rgb(211, 217, 219)",
                  borderRadius: "8px",
                  backgroundColor: lightest,
                },
              }}
              control={control}
            />
            <TextInput<Inputs>
              name="email"
              label="Email"
              value={email}
              onChange={(e) => setEmailHandler(e)}
              sx={{
                label: {
                  paddingLeft: "10px",
                  color: "rgb(2, 42, 55)",
                  zIndex: 1,
                },
                input: {
                  paddingLeft: "10px",
                  border: "1px solid rgb(211, 217, 219)",
                  borderRadius: "8px",
                  backgroundColor: lightest,
                },
              }}
              control={control}
            />
            <RadioButtonGroupInput
              name="role"
              control={control}
              label="Role"
              data={[
                { id: "Standard", value: "User" },
                { id: "OrgAdmin", value: "Admin" },
              ]}
              value={role}
              setValue={setRole}
              sx={{
                margin: "16px",
                label: {
                  paddingLeft: "10px",
                  color: "rgb(2, 42, 55)",
                },
              }}
            />
            <FormControlLabel
              name="notifyByEmail"
              label="Send password reset email"
              control={
                <Switch
                  defaultChecked
                  sx={{
                    marginLeft: "20px",
                    label: {
                      paddingLeft: "10px",
                      color: "rgb(2, 42, 55)",
                      zIndex: 1,
                    },
                    input: {
                      paddingLeft: "10px",
                      border: "1px solid rgb(211, 217, 219)",
                      borderRadius: "8px",
                      backgroundColor: lightest,
                    },
                  }}
                  {...register("notifyByEmail")}
                />
              }
            />
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "inherit", margin: "16px" }}
          >
            <AddUserButton
              size="small"
              sx={{ height: "40px" }}
              startIcon={
                isLoading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : null
              }
              type="submit"
            >
              Add User
            </AddUserButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                marginLeft: 4,
              }}
            >
              <FormControlLabel
                name="addAnotherUser"
                label="Add another user"
                control={
                  <Checkbox
                    checked={enableSequenceAdd}
                    onChange={handleChangeSequenceAdd}
                  />
                }
              />
            </Box>
          </Box>
          {errorMessage && (
            <ErrorLabel errorMessage={errorMessage}></ErrorLabel>
          )}
        </Box>
      </Box>
      {openToast && (
        <Toast
          message="Unable to create account."
          isOpen={openToast}
          duration={4000}
          onClose={() => {
            setOpenToast(false);
          }}
        />
      )}
    </form>
  );
};

export default AddUser;
