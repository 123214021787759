import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { Clear } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CompanyCard from "./CompanyCard";
import Search from "components/Search";
import ErrorMessage from "components/ErrorMessage";
import { primary_dark } from "../variables";
import { Userpilot } from "userpilot";
import useOrders from "./useOrders";
import StyledButton from "components/Button/Button";
import { useDebounceValue } from "usehooks-ts";

const centeredStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function OrderHistory(): JSX.Element {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
    null,
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
    null,
  );
  const [orderTypes, setOrderTypes] = useState<string>("false");

  // Don't call the API search until there's a little break in typing
  const [searchTerm, setSearchTerm] = useDebounceValue("", 500);

  const onSearchInput = (input: string) => {
    setSearchTerm(input);
  };

  const onStartDateChange = (newValue: Date | null) => {
    Userpilot.track("Order History Start Date Changed");
    setSelectedStartDate(newValue);
    if (selectedEndDate && newValue && selectedEndDate < newValue) {
      setSelectedEndDate(newValue);
    }
  };

  const onEndDateChange = (newValue: Date | null) => {
    Userpilot.track("Order History End Date Changed");
    setSelectedEndDate(newValue);
  };

  const onOrderTypesChange = (event: SelectChangeEvent<string>) => {
    setOrderTypes(event.target.value);
    Userpilot.track("Order History All/My Orders Changed");
  };

  const {
    orders,
    queryApi: {
      isError,
      isLoading,
      fetchNextPage,
      hasNextPage,
      isFetching,
      isFetchingNextPage,
    },
    ordersSeen,
    maxOrders,
    visibleOrders,
  } = useOrders(selectedStartDate, selectedEndDate, searchTerm, orderTypes);

  let loadMoreText = "Load next 500 orders";
  if (searchTerm.trim() !== "") {
    if (orders.size > 0) {
      loadMoreText = "Search next 5000 orders";
    } else {
      loadMoreText = "Continue Searching";
    }
  }

  const getMinDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 30);
    return today;
  };

  useEffect(() => {
    Userpilot.track("Entered OrderHistory page");
  }, []);
  return (
    <Box
      data-testid="order-history-root"
      sx={(theme) => ({
        display: "flex",
        padding: "50px 70px",
        flexDirection: "column",
        [theme.breakpoints.down("sm")]: {
          padding: "40px 25px",
        },
      })}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h4"
          fontSize="2.375rem"
          fontFamily="Sohne"
          color="primary.dark"
          mb={1}
        >
          Order History
        </Typography>
        <Box
          sx={(theme) => ({
            display: "flex",
            alignItems: "center",
            [theme.breakpoints.down("sm")]: {
              gridTemplateAreas: `"a a a a""b c d"`,
              display: "grid",
            },
          })}
        >
          <Search
            textInputProps={{
              onFocus: () =>
                Userpilot.track("Order History Company Search Focused"),
              placeholder: "Search company names",
            }}
            onSearchInput={onSearchInput}
            hasClearInputButton
          />
          <FormControl
            size="small"
            sx={{
              borderRadius: 2,
              mr: 2,
              backgroundColor: "primary.light",
            }}
          >
            <Select
              labelId="show-org-orders"
              data-testid="show-all-orders-select"
              id="show-all-orders"
              required
              onChange={onOrderTypesChange}
              value={orderTypes}
              sx={{
                fontSize: "0.8rem",
                border: "none",
                padding: "3px",
              }}
            >
              <MenuItem value={"true"}>All Orders</MenuItem>
              <MenuItem value={"false"}>My Orders</MenuItem>
            </Select>
          </FormControl>
          <DesktopDatePicker
            label="Start Date"
            inputFormat="dd/MM/yyyy"
            value={selectedStartDate}
            onChange={onStartDateChange}
            disableFuture
            minDate={getMinDate()}
            maxDate={selectedEndDate ?? undefined}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                size="small"
                aria-label="date-input-field"
                sx={(theme) => ({
                  label: {
                    color: theme.palette.primary.dark,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid transparent",
                    borderRadius: "5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    color: theme.palette.primary.dark,
                  },
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: "5px",
                })}
              />
            )}
            InputAdornmentProps={{
              position: "start",
            }}
            PopperProps={{
              sx: {
                "& .MuiPaper-root": {
                  color: primary_dark,
                },
              },
            }}
            InputProps={{
              sx: {
                "& .MuiSvgIcon-root": {
                  color: primary_dark,
                },
              },
              endAdornment: (
                <IconButton
                  aria-label="clear-date-filter"
                  sx={{ alignSelf: "center" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onStartDateChange(null);
                  }}
                >
                  <Clear sx={{ color: "primary.dark" }} fontSize="small" />
                </IconButton>
              ),
            }}
          />
          <Box
            sx={(theme) => ({
              display: "none",
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                display: "block",
                padding: "5px",
              },
            })}
          ></Box>
          <Typography
            variant="h4"
            fontSize="0.75rem"
            color="primary.dark"
            sx={(theme) => ({
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
              padding: "10px",
            })}
          >
            -
          </Typography>
          <DesktopDatePicker
            label="End Date"
            inputFormat="dd/MM/yyyy"
            value={selectedEndDate}
            onChange={onEndDateChange}
            disableFuture
            minDate={selectedStartDate ?? getMinDate()}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="off"
                size="small"
                aria-label="date-input-field"
                sx={(theme) => ({
                  label: {
                    color: theme.palette.primary.dark,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid transparent",
                    borderRadius: "5px",
                  },
                  "& .MuiOutlinedInput-root": {
                    color: theme.palette.primary.dark,
                  },
                  backgroundColor: theme.palette.primary.light,
                  borderRadius: "5px",
                })}
              />
            )}
            InputAdornmentProps={{
              position: "start",
            }}
            PopperProps={{
              sx: {
                "& .MuiPaper-root": {
                  color: primary_dark,
                },
              },
            }}
            InputProps={{
              sx: {
                "& .MuiSvgIcon-root": {
                  color: primary_dark,
                },
              },
              endAdornment: (
                <IconButton
                  aria-label="clear-date-filter"
                  sx={{ alignSelf: "center" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onEndDateChange(null);
                  }}
                >
                  <Clear sx={{ color: "primary.dark" }} fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="caption" color="grey.500" fontWeight={500}>
          Showing {visibleOrders} orders
        </Typography>
      </Box>
      {Array.from(orders).map(([date, groups]) => {
        return (
          <Box
            data-testid={`order-history-${date}`}
            key={date}
            sx={{
              "&:not(:first-of-type)": {
                marginTop: 9,
              },
            }}
          >
            <Typography
              fontWeight={500}
              fontSize="1.125rem"
              color="primary.dark"
            >
              {date}
            </Typography>
            {Array.from(groups).map(([id, orders]) => (
              <CompanyCard orders={orders} key={`${date}${id}`} />
            ))}
          </Box>
        );
      })}
      {(isLoading || isFetching) && (
        <Box
          ref={contentRef}
          sx={{
            ...centeredStyle,
            height: orders.size === 0 ? `calc(80vh)` : `5rem`,
          }}
        >
          <Box
            data-testid="order-history-loading"
            sx={{ display: "flex", gap: 2 }}
          >
            <CircularProgress color="success" size="2rem" />
            <Typography
              variant="caption"
              color="grey.500"
              fontWeight={500}
              paddingTop="0.4rem"
            >
              Loading...
            </Typography>
          </Box>
        </Box>
      )}
      {isError && (
        <Box
          sx={{
            ...centeredStyle,
            height: `5rem`,
          }}
        >
          <ErrorMessage message="Error loading page of Order History" />
        </Box>
      )}
      {!(isLoading || isFetching) && !isError && orders.size === 0 && (
        <Box
          sx={{
            ...centeredStyle,
            height: `5rem`,
          }}
        >
          <ErrorMessage message="No orders found" />
        </Box>
      )}
      {hasNextPage && !(isLoading || isFetching) && (
        <>
          <Box
            sx={{
              ...centeredStyle,
              height: `5rem`,
            }}
          >
            <StyledButton
              onClick={() => {
                fetchNextPage();
                Userpilot.track("Order History Load More Orders Clicked");
              }}
              disabled={isFetchingNextPage}
            >
              {isFetchingNextPage ? "Loading more..." : loadMoreText}
            </StyledButton>
          </Box>
          <Box
            sx={{
              ...centeredStyle,
              height: `2rem`,
            }}
          >
            {searchTerm && ordersSeen && (
              <Typography variant="caption" color="grey.500" fontWeight={500}>
                Searched {ordersSeen} of {maxOrders} orders
              </Typography>
            )}
          </Box>
        </>
      )}
      {!isLoading && !hasNextPage && (
        <Box
          sx={{
            ...centeredStyle,
            height: `5rem`,
          }}
        >
          <Typography variant="caption" color="grey.500" fontWeight={500}>
            End of Results
          </Typography>
        </Box>
      )}
    </Box>
  );
}
