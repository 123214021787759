import { useState, useEffect } from "react";
import { LogoutOptions, useAuth0 } from "@auth0/auth0-react";

export type User = {
  email?: string;
  name?: string;
  nickname?: string;
  picture?: string;
  roles?: string[];
};

export const useKyckrUser = () => {
  const [user, setUser] = useState<User | undefined>();
  const { user: auth0user, logout: auth0Logout } = useAuth0();
  useEffect(() => {
    if (auth0user) {
      type ObjectKey = keyof typeof auth0user;
      const roles = "https://kyckr.com/roles" as ObjectKey;
      const loggedInUser: User = {
        email: auth0user?.email,
        name: auth0user?.name,
        nickname: auth0user?.nickname,
        picture: auth0user?.picture,
        roles: auth0user ? (auth0user[roles] as string[]) : undefined,
      };

      setUser(loggedInUser);
    }
  }, [auth0user]);

  const logout: (options?: LogoutOptions) => void = function (options) {
    setUser(undefined);
    auth0Logout(options);
  };

  return { user, logout };
};
