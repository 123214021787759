import React, { useContext } from "react";
import { LogoutOptions } from "@auth0/auth0-react";
import { useKyckrUser, User } from "hooks/useKyckrUser";

interface Props {
  children?: React.ReactNode;
}

type UserContextValue = {
  user: User | undefined;
  logout: (options?: LogoutOptions) => void;
  isAdmin: boolean;
};

export const UserContext = React.createContext<UserContextValue | undefined>(
  undefined,
);

export function useUserContext(): UserContextValue {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserContext");
  }

  return context;
}

export const UserContextProvider: React.FC<Props> = ({ children }: Props) => {
  const { user, logout } = useKyckrUser();
  const isAdmin = user?.roles?.includes("ADMIN") || false;

  const value = React.useMemo(
    () => ({
      user,
      isAdmin,
      logout,
    }),
    // TODO:: why is this memoized?
    [user, isAdmin, logout],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
