import { QueryKey, useMutation, useQueryClient } from "react-query";
import useAddUserFetcher from "./useAddUserFetcher";

export type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  credits: number;
  role: string;
  notifyByEmail: string;
};

export const useAddUser = (
  toastHandler: (v: string, f: boolean, isEditAction: boolean) => void,
) => {
  const fetcher = useAddUserFetcher();

  const queryClient = useQueryClient();

  return useMutation(
    (params: Inputs) => {
      return fetcher({
        ...params,
      });
    },
    {
      onSuccess: () => {
        const base = `${process.env.REACT_APP_PORTAL_API_URL}`;
        const fullPath = `${base}/accounts/organization/members`;
        const queryKey: QueryKey = [fullPath];
        queryClient.refetchQueries(queryKey);
        toastHandler("Successfully created user.", false, false);
      },
    },
  );
};
